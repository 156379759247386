import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ToggleColorMode from './ToggleColorMode';
import LavalLogo from './LavalLogo';
import Sitemark from './SitemarkIcon';

function AppAppBar({ mode, toggleColorMode }) {

	const scrollToSection = (sectionId) => {
		console.log(`Scrolling to ${sectionId}`)
		const sectionElement = document.getElementById(sectionId);
		console.log(`sectionElement - `, sectionElement)
		const offset = 128;
		if (sectionElement) {
			const targetScroll = sectionElement.offsetTop - offset;
			console.log(`targetScroll - ${targetScroll}`)
			sectionElement.scrollIntoView({ behavior: 'smooth' });

			window.scrollTo({
				top: targetScroll,
				behavior: 'smooth',
			});
			setOpen(false);
		}
	};

	const [open, setOpen] = React.useState(false);

	const toggleDrawer = (newOpen) => () => {
		setOpen(newOpen);
	};

	const navBtnStyle =
	{
		fontSize: '1rem',
		fontWeight: 'bold',
	}


	return (
		<AppBar
			position="fixed"
			sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2 }}
		>
			<Container maxWidth="lg">
				<Toolbar
					variant="regular"
					sx={(theme) => ({
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%',
						flexShrink: 0,
						borderRadius: '999px',
						backdropFilter: 'blur(24px)',
						maxHeight: 40,
						border: '1px solid',
						borderColor: 'divider',
						bgcolor: 'hsla(220, 60%, 99%, 0.6)',
						boxShadow:
							'0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5)',
						...theme.applyStyles('dark', {
							bgcolor: 'hsla(220, 0%, 0%, 0.7)',
							boxShadow:
								'0 1px 2px hsla(210, 0%, 0%, 0.5), 0 2px 12px hsla(210, 100%, 25%, 0.3)',
						}),
					})}
				>
					<Box sx={{
						flexGrow: 1,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}>
						{/* Logo */}
						<Box sx={{
							display: 'flex',
							justifyContent: 'space-between', alignItems: 'center', gap: 1, m: 0,

						}}>
							<LavalLogo onClick={() => scrollToSection('root')} />
						</Box>
						{/* Nav items */}
						{/* <Box sx={{
							display: { xs: 'none', md: 'flex' },
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: 1,
						}}>
							<Button
								variant="text"
								color="info"
								size="small"
								onClick={() => scrollToSection('services')}
								style={navBtnStyle}
							>
								Services
							</Button>
							<Button
								variant="text"
								color="info"
								size="small"
								onClick={() => scrollToSection('clinics')}
								style={navBtnStyle}
							>
								Clinics
							</Button>
							<Button
								variant="text"
								color="info"
								size="small"
								onClick={() => scrollToSection('contact')}
								style={navBtnStyle}
							>
								Contact
							</Button>
							<Button
								variant="text"
								color="info"
								size="small"
								onClick={() => scrollToSection('faq')}
								style={navBtnStyle}
							>
								FAQ
							</Button>
						</Box> */}
						{/* Signup / Signin */}
						<Box
							sx={{
								display: { xs: 'none', md: 'flex' },
								gap: 1,
								alignItems: 'center',
							}}
						>
							<Box sx={{
								display: { xs: 'none', md: 'flex' },
								justifyContent: 'space-between',
								alignItems: 'center',
								gap: 1,
							}}>
								<Button
									variant="text"
									color="info"
									size="small"
									onClick={() => scrollToSection('services')}
									style={navBtnStyle}
								>
									Services
								</Button>
								<Button
									variant="text"
									color="info"
									size="small"
									onClick={() => scrollToSection('clinics')}
									style={navBtnStyle}
								>
									Clinics
								</Button>
								<Button
									variant="text"
									color="info"
									size="small"
									onClick={() => scrollToSection('contact')}
									style={navBtnStyle}
								>
									Contact
								</Button>
								<Button
									variant="text"
									color="info"
									size="small"
									onClick={() => scrollToSection('faq')}
									style={navBtnStyle}
								>
									FAQ
								</Button>
							</Box>
							{/* <Button color="primary" variant="contained" size="small" onClick={() => scrollToSection('contact')}>
								Contact me
							</Button> */}
							{/* <Button color="primary" variant="contained" size="small" onClick={
								() => {
									window.location.href = 'https://app.insighealth.com/virtual/book-appointment/TnxJ5ZxJkZbLB493WVeaty2KwVtQhdl2;planID=z7H0AYFpyit8VfY8iNkO;medium=video#N4IgzgpgNhDGAuEAmBlRAHdEBOBJAdkhAB4gBcAjADTjRyJICyyAlgK4C25IAbi0QHsQNSDATICSFnyRsAhlHL42UKCLrikAGQGw58FgPxKVa2mIYARXfAHYTq9ReQBBTAJb54HCF4AqAJ5YDmai9K7unt6+8CEiABYCAO4A8vhQAS48cixQcgBGMH4CSHIB5ABmCpBO4UiWZXHmdX4sPiEAvkA', '_blank'
								}
							}>
								Sign up
							</Button> */}
							<ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} sx={{ ml: 2 }} />
						</Box>
						{/* Mobile menu */}
						<Box sx={{ display: { sm: 'flex', md: 'none' } }}>
							<IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
								<MenuIcon />
							</IconButton>
							<Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
								<Box sx={{ p: 2, backgroundColor: 'background.default' }}>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										{/* Logo */}
										<Box sx={{
											display: 'flex',
											justifyContent: 'space-between', alignItems: 'center', gap: 1, m: 0,

										}}>
											<LavalLogo onClick={() => scrollToSection('root')} />
										</Box>
										<Box sx={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'flex-end', alignItems: 'center', gap: 1, m: 0,
										}}>
											<ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
											<IconButton onClick={toggleDrawer(false)}>
												<CloseRoundedIcon />
											</IconButton>
										</Box>
									</Box>
									<Divider sx={{ my: 3 }} />
									<MenuItem onClick={() => scrollToSection('services')}>
										Services
									</MenuItem>
									<MenuItem onClick={() => scrollToSection('contact')}>
										Contact
									</MenuItem>
									<MenuItem onClick={() => scrollToSection('clinics')}>
										Clinics
									</MenuItem>
									<MenuItem onClick={() => scrollToSection('faq')}>FAQ</MenuItem>
									{/* <MenuItem>
										<Button color="primary" variant="contained" fullWidth onClick={
											() => {
												window.location.href = 'https://app.insighealth.com/virtual/book-appointment/TnxJ5ZxJkZbLB493WVeaty2KwVtQhdl2;planID=z7H0AYFpyit8VfY8iNkO;medium=video#N4IgzgpgNhDGAuEAmBlRAHdEBOBJAdkhAB4gBcAjADTjRyJICyyAlgK4C25IAbi0QHsQNSDATICSFnyRsAhlHL42UKCLrikAGQGw58FgPxKVa2mIYARXfAHYTq9ReQBBTAJb54HCF4AqAJ5YDmai9K7unt6+8CEiABYCAO4A8vhQAS48cixQcgBGMH4CSHIB5ABmCpBO4UiWZXHmdX4sPiEAvkA', '_blank'
											}
										} >
											Sign up
										</Button>
									</MenuItem> */}
								</Box>
							</Drawer>
						</Box>
					</Box>
				</Toolbar>
			</Container>
		</AppBar >
	);
}

AppAppBar.propTypes = {
	mode: PropTypes.oneOf(['dark', 'light']).isRequired,
	toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
