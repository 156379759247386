// src/components/ContactUs.js
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Form from './Form';
import theme from '../theme';


// Fix marker icon issue with Leaflet and Webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
	iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
	iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
	shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});




const ContactUs = () => {
	const appletreePosition = [45.3505, -75.8046]; // Coordinates for Appletree Medical Group
	const pulsepointPosition = [45.3625, -75.7467]; // Coordinates for Pulsepoint Medical Center

	const tiers = [
		{
			title: 'Appletree Medical Group',
			subtitle: '2150 Robertson Road',
			description: [
				'Monday: 9 am - 5 pm',
				'Tuesday: 9 am - 3 pm',
				'Wednesday: 9 am - 4 pm',
				'Friday: 9 am - 3 pm'
			],
			map: () => {
				return (<MapContainer center={appletreePosition} zoom={15} style={{ height: '400px', width: '100%' }}>
					<TileLayer
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					/>
					<Marker position={appletreePosition}>
						<Popup>Appletree Medical Group</Popup>
					</Marker>
				</MapContainer>)
			}
		},
		{
			title: 'PulsePoint Medical Center',
			subtitle: '2730 Iris Street Unit 5',
			price: '30',
			description: [
				'Thursday: 9 am - 4 pm'
			],
			map: () => {
				return (<MapContainer center={pulsepointPosition} zoom={15} style={{ height: '400px', width: '100%' }}>
					<TileLayer
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					/>
					<Marker position={pulsepointPosition}>
						<Popup>PulsePoint Medical Center</Popup>
					</Marker>
				</MapContainer>)
			}
		},
	];
	return (
		<>
			<Container id="contact" sx={{
				pb: { xs: theme.spacing(8), sm: theme.spacing(16) },
				pt: {
					sm: theme.spacing(6),
					md: theme.spacing(2),
				},
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				justifyContent: 'center',
			}}>
				<Box
					sx={{
						width: '100%',
						textAlign: { sm: 'left', md: 'left' },
						mt: { xs: 2, sm: 4, md: 6 },
					}}
				>
					<Box sx={{
						width: { sm: '100%', md: '60%' },
					}}>
						<Typography component="h2" variant="h4" sx={{ color: 'text.primary' }}>
							Contact me
						</Typography>
					</Box>
					<Typography variant="body1" sx={{ color: 'text.secondary' }}>
						{/* You can find me at the following locations, by appointment only. <br /><br />
						<b>To schedule an appointment: </b><br />
						Call the Appletree number <a
							href="tel:6131234567">613-123-4567</a> or <br /> */}
						
						<b>For existing patients, with a referral:</b><br />
						You can leave me a message using the contact form below. <br /><br />
						<b>For new patients or if you do not yet have a referral:</b><br />
						See details about my clinics section for details on how to arrange a referral. <br /><br />
					</Typography>

					<Form />
				</Box>
			</Container>
		</>
	);
};

export default ContactUs;
