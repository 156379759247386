import React from 'react'
import { Box, Typography } from '@mui/material/'

const LavalLogo = ({ onClick }) => {
	return (
		<Typography
			color="primary"
			onClick={() => onClick()}
			sx={{
				textAlign: 'center',
				cursor: 'pointer',
				fontSize: '1.25rem',
				fontWeight: 'bold',
				fontFamily: "Borel, cursive",
				position: 'relative',
				top: '6px',
			}}
			component={'span'}

		>
			Dr. Laval
		</Typography>
	)
}

export default LavalLogo