import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LavalLogo from './LavalLogo';
import { visuallyHidden } from '@mui/utils';

import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';

import SitemarkIcon from './SitemarkIcon';

import Social from './Social';

function Copyright() {
	return (
		<Box sx={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'row',
			gap: 1,

		}}>
			{/* Gravatar Image */}
			<Box sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'row',
				gap: 1,

			}}>
				<img style={{
					borderRadius: '50%',
					width: '32px',
					height: '32px',
					marginTop: '8px',

				}} src={"https://gravatar.com/avatar/8d1bfd93373afd04c8cf5a6fb3e35c0d?size=256&cache=1720573539790"} alt="Gravatar" />
			</Box>
			<Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
				{'Copyright © '}
				<Link href="https://gravatar.com/eduardkleiner/">Eduard Kleiner&nbsp;</Link>
				{new Date().getFullYear()}
			</Typography>

		</Box>
	);
}

export default function Footer() {
	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: { xs: 4, sm: 8 },
				py: { xs: 8, sm: 10 },
				textAlign: { sm: 'center', md: 'left' },
			}}
		>

			{/* <Box
				sx={{
					display: 'flex',
					flexDirection: { xs: 'column', sm: 'row' },
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 4,
						minWidth: { xs: '100%', sm: '60%' },
					}}
				>
					<Box sx={{ width: { xs: '100%', sm: '60%' } }}>
						<SitemarkIcon />
						<Typography variant="body2" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
							Join the newsletter
						</Typography>
						<Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
							Subscribe for weekly updates. No spams ever!
						</Typography>
						<Stack direction="row" spacing={1} useFlexGap>
							<InputLabel htmlFor="email-newsletter" sx={visuallyHidden}>
								Email
							</InputLabel>
							<TextField
								id="email-newsletter"
								hiddenLabel
								size="small"
								variant="outlined"
								fullWidth
								aria-label="Enter your email address"
								placeholder="Your email address"
								slotProps={{
									htmlInput: {
										autoComplete: 'off',
										'aria-label': 'Enter your email address',
									},
								}}
							/>
							<Button variant="contained" color="primary" sx={{ flexShrink: 0 }}>
								Subscribe
							</Button>
						</Stack>
					</Box>
				</Box>
				<Box
					sx={{
						display: { xs: 'none', sm: 'flex' },
						flexDirection: 'column',
						gap: 1,
					}}
				>
					<Typography variant="body2" sx={{ fontWeight: 'medium' }}>
						Product
					</Typography>
					<Link color="text.secondary" variant="body2" href="#">
						Features
					</Link>
					<Link color="text.secondary" variant="body2" href="#">
						Testimonials
					</Link>
					<Link color="text.secondary" variant="body2" href="#">
						Highlights
					</Link>
					<Link color="text.secondary" variant="body2" href="#">
						Pricing
					</Link>
					<Link color="text.secondary" variant="body2" href="#">
						FAQs
					</Link>
				</Box>
				<Box
					sx={{
						display: { xs: 'none', sm: 'flex' },
						flexDirection: 'column',
						gap: 1,
					}}
				>
					<Typography variant="body2" sx={{ fontWeight: 'medium' }}>
						Company
					</Typography>
					<Link color="text.secondary" variant="body2" href="#">
						About us
					</Link>
					<Link color="text.secondary" variant="body2" href="#">
						Careers
					</Link>
					<Link color="text.secondary" variant="body2" href="#">
						Press
					</Link>
				</Box>
				<Box
					sx={{
						display: { xs: 'none', sm: 'flex' },
						flexDirection: 'column',
						gap: 1,
					}}
				>
					<Typography variant="body2" sx={{ fontWeight: 'medium' }}>
						Legal
					</Typography>
					<Link color="text.secondary" variant="body2" href="#">
						Terms
					</Link>
					<Link color="text.secondary" variant="body2" href="#">
						Privacy
					</Link>
					<Link color="text.secondary" variant="body2" href="#">
						Contact
					</Link>
				</Box>
			</Box> */}

			<Box sx={{
				display: 'flex',
				justifyContent: 'space-between', alignItems: 'center', gap: 1, m: 0,
				flexDirection: 'column',

			}}>
				<LavalLogo onClick={() => scrollToSection('root')} />
				<Typography component="h4" variant="h4" sx={{ color: 'text.primary', fontFamily: 'Borel', fontSize: '1.5rem', mt: 0 }}>
					The Care You Deserve!
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					pt: { xs: 4, sm: 8 },
					width: '100%',
					borderTop: '1px solid',
					borderColor: 'divider',
				}}
			>
				<div>
					{/* <Link color="text.secondary" variant="body2" href="#">
						Privacy Policy
					</Link>
					<Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
						&nbsp;•&nbsp;
					</Typography> */}
					{/* <Link color="text.secondary" variant="body2" href="#">
						Terms of Service
					</Link> */}
					<Copyright />
				</div>
				<Social />
			</Box>
		</Container>
	);
}
