import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Chip as MuiChip } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinksModal from './LinksModal';
import SimpleModal from './SimpleModal';
import theme from '../theme';

import { styled } from '@mui/material/styles';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import VideoCallIcon from '@mui/icons-material/VideoCall';

const items = [
	{
		icon: <PregnantWomanIcon />,
		title: 'Obstetrical Care',
		description: 'Comprehensive prenatal and postnatal care for a healthy pregnancy and delivery.',
		imageLight: "/static/assets/services/Obstetrical_Care.webp",
		imageDark: "/static/assets/services/Obstetrical_Care.webp",
		LearnMore: () => { return (<LinksModal btnTitle="Obstetrical Links" fetchLink='https://api.airtable.com/v0/appT7HYUi34nlTals/tbl8y6GZEHnwoDwaf' title='Obstetrical Links' />) },
	},
	{
		icon: <LocalHospitalIcon />,
		title: 'Gynecological Care',
		description: 'Expert care for women’s health, including routine exams and specialized treatments.',
		imageLight: "/static/assets/services/Gynecological_Care.webp",
		imageDark: "/static/assets/services/Gynecological_Care.webp",
		LearnMore: () => { return (<LinksModal btnTitle="Gynaecology Links" fetchLink='https://api.airtable.com/v0/appT7HYUi34nlTals/tbloimUu0pfzAvwmP' title='Gynecological Links' />) },
	},
	{
		icon: <MedicalServicesIcon />,
		title: 'Colposcopy',
		description: 'Advanced diagnostic procedure to examine cervical health and detect abnormalities.',
		imageLight: "/static/assets/services/Colposcopy.webp",
		imageDark: "/static/assets/services/Colposcopy.webp",
		LearnMore: null,
	},
	{
		icon: <VideoCallIcon />,
		title: 'Virtual Care',
		description: 'Convenient online consultations and follow-ups for your health needs from home via Appletree.',
		imageLight: "/static/assets/services/Virtual_Care.webp",
		imageDark: "/static/assets/services/Virtual_Care.webp",
		LearnMore: () => { return (<SimpleModal btnTitle="Learn More" />) },
	},
];


const Chip = styled(MuiChip)(({ theme }) => ({
	variants: [
		{
			props: ({ selected }) => selected,
			style: {
				background:
					'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
				color: 'hsl(0, 0%, 100%)',
				borderColor: theme.palette.primary.light,
				'& .MuiChip-label': {
					color: 'hsl(0, 0%, 100%)',
				},
				...theme.applyStyles('dark', {
					borderColor: theme.palette.primary.dark,
				}),
			},
		},
	],
}));

export default function Services() {
	const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

	const handleItemClick = (index) => {
		setSelectedItemIndex(index);
	};



	const selectedFeature = items[selectedItemIndex];

	const SelectedLearnMore = items[selectedItemIndex]?.LearnMore || (() => null);

	return (
		<Container id="services" sx={{
			pb: { xs: theme.spacing(8), sm: theme.spacing(16) },
			pt: {
				sm: theme.spacing(6),
				md: theme.spacing(2),
			}
		}}>
			<div>
				<Typography component="h2" variant="h4" sx={{ color: 'text.primary' }}>
					Services
				</Typography>
				<Typography
					variant="body1"
					sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 }, maxWidth: { xs: '100%', sm: '750px' } }}
				>
					Comprehensive Women's Health Services: Expert Obstetrical Care, Advanced Colposcopy for Abnormal PAP, Specialized Gynecological Care, and Convenient Remote Virtual Care
				</Typography>
			</div>
			<Grid container spacing={6}>
				<Grid item xs={12} md={6}>
					<Grid container item sx={{ gap: 1, display: { xs: 'auto', sm: 'none' } }}>
						{items.map(({ title, LearnMore }, index) => (
							<Chip
								key={index}
								label={title}
								onClick={() => handleItemClick(index)}
								selected={selectedItemIndex === index}
							/>
						))}
					</Grid>
					<Card
						variant="outlined"
						sx={{ display: { xs: 'auto', sm: 'none' }, mt: 4 }}
					>
						{/* Mobile Image */}
						<Box sx={{ px: 2, pb: 2 }}>
							<Box sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: '100%',
								overflow: 'hidden',
								mb: 2,
							}}>
								<img src={items[selectedItemIndex].imageLight} alt="Colposcopy" style={{
									width: '100%',
								}} />
							</Box>
							<Typography
								gutterBottom
								sx={{ color: 'text.primary', fontWeight: 'medium' }}
							>
								{selectedFeature.title}
							</Typography>
							<Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
								{selectedFeature.description}
							</Typography>
							<Link
								color="primary"
								variant="body2"
								sx={{
									fontWeight: 'bold',
									display: 'inline-flex',
									alignItems: 'center',
									'& > svg': { transition: '0.2s' },
									'&:hover > svg': { transform: 'translateX(2px)' },
									cursor: 'pointer',
								}}
							>
								<SelectedLearnMore />
							</Link>
						</Box>
					</Card>
					<Stack
						direction="column"
						spacing={2}
						useFlexGap
						sx={{
							justifyContent: 'center',
							alignItems: 'flex-start',
							width: '100%',
							display: { xs: 'none', sm: 'flex' },
						}}
					>
						{items.map(({ icon, title, description, LearnMore }, index) => (
							<Card
								key={index}
								component={Button}
								onClick={() => handleItemClick(index)}
								sx={[
									(theme) => ({
										p: 3,
										height: 'fit-content',
										width: '100%',
										background: 'none',
										'&:hover': {
											background:
												'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
											borderColor: 'primary.light',
											boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)',
											...theme.applyStyles('dark', {
												background:
													'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
												borderColor: 'primary.dark',
												boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
											}),
										},
									}),
									selectedItemIndex === index &&
									((theme) => ({
										backgroundColor: 'action.selected',
										borderColor: 'primary.light',
										...theme.applyStyles('dark', {
											borderColor: 'primary.dark',
										}),
									})),
								]}
							>
								<Box
									sx={{
										width: '100%',
										display: 'flex',
										textAlign: 'left',
										flexDirection: { xs: 'column', md: 'row' },
										alignItems: { md: 'center' },
										gap: 2.5,
									}}
								>
									<Box
										sx={[
											(theme) => ({
												color: 'grey.400',
												...theme.applyStyles('dark', {
													color: 'grey.600',
												}),
											}),
											selectedItemIndex === index && {
												color: 'primary.main',
											},
										]}
									>
										{icon}
									</Box>
									<div>
										<Typography
											gutterBottom
											sx={{ color: 'text.primary', fontWeight: 'medium' }}
										>
											{title}
										</Typography>
										<Typography
											variant="body2"
											sx={{ color: 'text.secondary', mb: 1.5 }}
										>
											{description}
										</Typography>

										{LearnMore && (
											<LearnMore />
										)}
									</div>
								</Box>
							</Card>
						))}
					</Stack>
				</Grid>
				{/* Desktop Image */}
				<Grid
					item
					xs={12}
					md={6}
					sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
				>
					<Card
						variant="outlined"
						sx={{
							height: '100%',
							width: '100%',
							display: { xs: 'none', sm: 'flex' },
							pointerEvents: 'none',
						}}
					>
						<Box
							sx={(theme) => ({
								m: 'auto',
								width: 420,
								height: 500,
								backgroundSize: 'contain',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								backgroundImage: 'var(--items-imageLight)',
								...theme.applyStyles('dark', {
									backgroundImage: 'var(--items-imageDark)',
								}),
							})}
							style={{
								'--items-imageLight': `url('${items[selectedItemIndex].imageLight}')`,
								'--items-imageDark': `url('${items[selectedItemIndex].imageDark}')`,
							}}
						/>
					</Card>
				</Grid>
			</Grid>
		</Container >
	);
}
