import * as React from 'react';
import Link from '@mui/material/Link';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import axios from 'axios';

const Separator = () => {
	return (
		<Box sx={{
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'flex-start',
			width: '100%',
			height: '100%',
		}}>
			<Box sx={{
				width: '65%',
				height: '3px',
				backgroundColor: 'text.secondary',
				marginTop: 2,
				marginBottom: 2,
			}} />
		</Box>
	)
};

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '85%',
	maxWidth: 1100,
	bgcolor: 'background.paper',
	border: '2px solid #00000033',
	boxShadow: 24,
	backgroundColor: 'background.paper',
	p: 4,
	maxHeight: '80vh',
	overflow: 'auto',
};

export default function SimpleModal({ btnTitle }) {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	return (
		<>
			<Link
				color="primary"
				variant="body2"
				onClick={(event) => {
					event.stopPropagation();
					handleOpen();
				}}
				sx={{
					fontWeight: 'bold',
					display: 'inline-flex',
					alignItems: 'center',
					'& > svg': { transition: '0.2s' },
					'&:hover > svg': { transform: 'translateX(2px)' },
				}}
			>
				<span>{btnTitle}</span>
				<ChevronRightRoundedIcon
					fontSize="small"
					sx={{ mt: '1px', ml: '2px' }}
				/>
			</Link>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Box sx={{
							display: 'flex',
							flexDirection: {
								xs: 'column-reverse',
								md: 'row',
							},
							alignItems: 'flex-start',
							gap: 2,
							width: '100%',
						}}>
							{/* Close icon */}
							<Box sx={{
								position: 'fixed',
								right: 0,
								top: 16,
							}}>
								<Button onClick={handleClose} sx={{
									borderRadius: '50%',
									backgroundColor: 'background.paper',
									color: 'text.primary',
									fontSize: '1.5rem',
									'&:hover': {
										backgroundColor: 'background.paper',
									},
								}}>X</Button>
							</Box>
							<Box sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								justifyContent: 'flex-start',
								width: '100%',
								mt: {
									xs: 2,
									md: 0,
								}
							}}>
								<Typography id="transition-modal-title" variant="h5" component="h2" sx={{ mt: 1, mb: 1 }}>
									Getting started with Virtual Care
								</Typography>
								<Separator />
								<Typography id="transition-modal-description" sx={{ mt: 2 }}>
									Virtual Care is a quick and easy way to connect to an Appletree Doctor using your smartphone device or Home Computer. <br />
									Using Virtual care you can setup appointments with your doctor at any time anywhere, giving you access to online video chats, surveys and OHIP Coverage.<br />
								</Typography>
								<Link href="https://app.insighealth.com/virtual/book-appointment/TnxJ5ZxJkZbLB493WVeaty2KwVtQhdl2;planID=z7H0AYFpyit8VfY8iNkO;medium=video#N4IgzgpgNhDGAuEAmBlRAHdEBOBJAdkhAB4gBcAjADTjRyJICyyAlgK4C25IAbi0QHsQNSDATICSFnyRsAhlHL42UKCLrikAGQGw58FgPxKVa2mIYARXfAHYTq9ReQBBTAJb54HCF4AqAJ5YDmai9K7unt6+8CEiABYCAO4A8vhQAS48cixQcgBGMH4CSHIB5ABmCpBO4UiWZXHmdX4sPiEAvkA" target="_blank" rel="noopener noreferrer" sx={{ mt: 2 }}>
									<Button variant="contained" color="primary" sx={{ borderRadius: '25px' }}>SIGN UP FOR VIRTUAL CARE</Button>
								</Link>
							</Box>
						</Box>
					</Box>
				</Fade>
			</Modal>
		</>
	);
}
