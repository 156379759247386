import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Container
			id="faq"
			sx={{
				pt: { xs: 4, sm: 12 },
				pb: { xs: 8, sm: 16 },
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: { xs: 3, sm: 6 },
			}}
		>
			<Typography
				component="h2"
				variant="h4"
				sx={{
					color: 'text.primary',
					width: { sm: '100%', md: '60%' },
					textAlign: { sm: 'left', md: 'center' },
				}}
			>
				Frequently asked questions
			</Typography>
			<Box sx={{ width: '100%' }}>
				<Accordion
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1d-content"
						id="panel1d-header"
					>
						<Typography component="h3" variant="subtitle2">
							How do I get a consultation with Doctor Laval?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							variant="body2"
							gutterBottom
							sx={{ maxWidth: { sm: '100%', md: '70%' } }}
						>
							I am a specialist and so you will need to get a referral. <br />
							You can get a referral one of three ways. 

						</Typography>
						<ul>
							<li>You can go to your Family doctor.</li>
							<li>You can go to a walk in clinic.</li>
							<li>If you are rostered to Appletree you may book a virtual appointment to get a referral.</li>
							<li>You can go to PulsePoint as a walk-in Monday, Tuesday, Thursday, alternate Friday's.</li>
							<li>You can book online or call PulsePoint.</li>
						</ul>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === 'panel2'}
					onChange={handleChange('panel2')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2d-content"
						id="panel2d-header"
					>
						<Typography component="h3" variant="subtitle2">
							What is your new patient procedure?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							variant="body2"
							gutterBottom
							sx={{ maxWidth: { sm: '100%', md: '70%' } }}
						>
							All initial appointments at Appletree and PulsePoint will be virtual. After you get a referral you will be contacted with a time for an appointment. You will be sent a link to fill out a questionnaire. It is very important that you take the time to fill it out. The questionnaire helps me to fully diagnose and treat your concern.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === 'panel3'}
					onChange={handleChange('panel3')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3d-content"
						id="panel3d-header"
					>
						<Typography component="h3" variant="subtitle2">
							What should I expect for my first in-clinic visit?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							variant="body2"
							gutterBottom
							sx={{ maxWidth: { sm: '100%', md: '70%' } }}
						>
							At Appletree You will need to register at the Kiosk. At PulsePoint you will need to speak to the receptionist. The medical assistant will ask you screening questions. You will be assigned to a room and she will take your blood pressure, check your height and weight and may ask for a urine test. Please bring medications and a copy of any relevant tests.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Box>
		</Container>
	);
}
