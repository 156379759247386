// src/components/ContactUs.js
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Form from './Form';
import theme from '../theme';


// Fix marker icon issue with Leaflet and Webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
	iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
	iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
	shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});




const ContactUs = () => {
	const appletreePosition = [45.32010341803229, -75.83742043161249]; // Coordinates for Appletree Medical Group
	const pulsepointPosition = [45.3478016332883, -75.78655258928245]; // Coordinates for Pulsepoint Medical Center

	const tiers = [
		{
			title: 'Appletree Medical Group',
			subtitle: '2150 Robertson Road',
			website: 'https://appletreemedicalgroup.com',
			phone: '613-482-0118',
			fax: '613-604-0277',
			description: [
				'Monday: 9 am - 5 pm',
				'Tuesday: 9 am - 3 pm',
				'Thursday: 9 am - 4 pm',
				'Friday: 9 am - 3 pm'
			],
			toBeSeen: `Patients require a referral. Once a referral is received, the clinic will contact you to arrange a virtual appointment based on priority and availability.`,
			map: () => {
				return (
					<MapContainer center={appletreePosition} zoom={15} style={{ height: '400px', width: '100%' }}
						dragging={false} touchZoom={false} scrollWheelZoom={false} doubleClickZoom={false} boxZoom={false} zoomControl={false}
						tap={false} keyboard={false} zoomSnap={false} zoomDelta={false} trackResize={false}
						animate={false} easeLinearity={false} bounceAtZoomLimits={false}
					>
						<TileLayer
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						/>
						<Marker position={appletreePosition}>
							<Popup>Appletree Medical Group</Popup>
						</Marker>
					</MapContainer>)
			}
		},
		{
			title: 'PulsePoint Medical Center',
			subtitle: '2730 Iris Street Unit 5 (inside Iris Pharmacy)',
			price: '30',
			website: 'https://pulsepointmedicalcenter.ca/',
			phone: '343-801-5323',
			fax: '833-466-0666',
			description: [
				'Wednesday: 9 am - 4 pm',
			],
			toBeSeen: `Patients require a referral. Once a referral is received, the clinic will contact you to arrange a virtual appointment based on priority and availability.`,
			map: () => {
				return (
					<MapContainer center={pulsepointPosition} zoom={15} style={{ height: '400px', width: '100%' }}
						dragging={false} touchZoom={false} scrollWheelZoom={false} doubleClickZoom={false} boxZoom={false} zoomControl={false}
						tap={false} keyboard={false} zoomSnap={false} zoomDelta={false} trackResize={false}
						animate={false} easeLinearity={false} bounceAtZoomLimits={false}
					>
						<TileLayer
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						/>
						<Marker position={pulsepointPosition}>
							<Popup>PulsePoint Medical Center</Popup>
						</Marker>
					</MapContainer>)
			}
		},
	];
	return (
		<>
			<Container id="clinics" sx={{
				pb: { xs: theme.spacing(8), sm: theme.spacing(16) },
				pt: {
					sm: theme.spacing(6),
					md: theme.spacing(2),
				},
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				justifyContent: 'center',
			}}>

				<Box sx={{
					mt: 8,
					mb: 0,
				}}>
					<Typography component="h2" variant="h4" sx={{ color: 'text.primary' }}>
						Clinics & Times
					</Typography></Box>
				{/* <Typography variant="body1" sx={{ color: 'text.secondary' }}>
					You can find me at the following locations, referral with an appointment only.<br /><br />
					<b>I see patients at the following location: </b><br />
				</Typography> */}

				<Grid
					container
					spacing={3}
					sx={{ my: 0, px: 0, alignItems: 'flex-start', justifyContent: 'flex-start', alignItems: 'stretch', }}
				>

					{tiers.map((tier) => (
						<Grid
							item
							key={tier.title}
							xs={12}
							sm={tier.title === 'Enterprise' ? 12 : 6}
							md={6}
							sx={{
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'flex-start',
								width: '100%',
								alignItems: 'stretch',
							}}
						>
							<Card
								sx={[
									{
										p: 2,
										display: 'flex',
										flexDirection: 'column',
										gap: 0,
										height: '100%',
										width: '100%',
										alignItems: 'stretch',

									},
									tier.title === 'Professional' &&
									((theme) => ({
										border: 'none',
										background:
											'radial-gradient(circle at 50% 0%, hsl(210, 98%, 35%), hsl(210, 100%, 16%))',
										boxShadow: `0 8px 12px hsla(210, 98%, 42%, 0.2)`,
										...theme.applyStyles('dark', {
											boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
										}),
									})),
								]}
							>
								<CardContent sx={{
									alignItems: 'stretch',
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',

								}}>
									<Box>
										<Box
											sx={[
												{
													mb: 1,
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
													gap: 2,
													alignItems: 'stretch',
												},
												tier.title === 'Professional'
													? { color: 'grey.100' }
													: { color: '' },
											]}
										>
											<Typography component="h3" variant="h6" sx={{}}>
												<b>Clinic: </b>{tier.title}
											</Typography>
											{tier.title === 'Professional' && (
												<Chip
													icon={<AutoAwesomeIcon />}
													label={tier.subheader}
													size="small"
													sx={{
														borderColor: 'hsla(220, 60%, 99%, 0.3)',
														backgroundColor: 'hsla(220, 60%, 99%, 0.1)',
														'& .MuiChip-label': {
															color: 'hsl(0, 0%, 100%)',
														},
														'& .MuiChip-icon': {
															color: 'primary.light',
														},
													}}
												/>
											)}
										</Box>
										<Box
											sx={[
												{
													display: 'flex',
													alignItems: 'baseline',
												},
												tier.title === 'Professional'
													? { color: 'grey.50' }
													: { color: null },
											]}
										>
											<Typography variant="h6" component="span">
												<b>Address: </b>{tier.subtitle}
											</Typography>
										</Box>

										<Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
										<Typography variant="h6" component="span">

											<Typography component="span" sx={{
												color: 'text.secondary',
												fontSize: 14,
												fontWeight: 'medium',
											}}><b>To be seen by Dr. Laval at this clinic - </b>{tier.toBeSeen}
											</Typography>
										</Typography>
										<Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
										<Box sx={{
											height: '400px',
											width: '100%',
										}}>
											{tier.map()}
										</Box>
										<Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
										<Typography variant="h6" component="span">
											<u>For referrals, please contact:</u><br />
											Phone: <a href={`tel:${tier.phone}`}>{tier.phone}</a><br />
											Fax: <a href={`tel:${tier.fax}`} >{tier.fax}</a><br />
											Website: <a href={tier.website} target="_blank">{tier.website}</a>
										</Typography>
										<Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
										<Typography variant="h6" component="span">
											Opening Hours:
										</Typography>
										{tier.description.map((line) => (
											<Box
												key={line}
												sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}
											>
												<CheckCircleRoundedIcon
													sx={[
														{
															width: 20,
														},
														tier.title === 'Professional'
															? { color: 'primary.light' }
															: { color: 'primary.main' },
													]}
												/>
												<Typography
													variant="subtitle2"
													component={'span'}
													sx={[
														tier.title === 'Professional'
															? { color: 'grey.50' }
															: { color: null },
													]}
												>
													{line}
												</Typography>
											</Box>
										))}
									</Box>

								</CardContent>
								{/* <CardActions>
								<Button fullWidth variant={tier.buttonVariant}>
									{tier.buttonText}
								</Button>
							</CardActions> */}
							</Card>
						</Grid>
					))}
				</Grid >
			</Container >
		</>
	);
};

export default ContactUs;
