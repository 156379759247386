import React from 'react';

const JotFormEmbed = () => {
  return (
    <div style={{ height: '600px', maxHeight: '80vh', width: '100%' }}>
      <iframe
        title="Contact Dr. Laval"
        src="https://form.jotform.com/241935560014249"
        frameBorder="0"
        style={{ height: '100%', width: '100%' }}
        scrolling="no"
      />
    </div>
  );
};

export default JotFormEmbed;