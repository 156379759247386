import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const logoStyle = {
	width: '64px',
	opacity: 0.3,
};

export default function AboutPractice() {
	const theme = useTheme();

	return (
		<Container
			id="testimonials"
			sx={{
				pt: { xs: 4, sm: 12 },
				pb: { xs: 8, sm: 16 },
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: { xs: 3, sm: 6 },
			}}
		>
			<Box
				sx={{
					width: { sm: '100%', md: '60%' },
					textAlign: { sm: 'left', md: 'center' },
				}}
			>
				<Typography component="h2" variant="h4" sx={{ color: 'text.primary', mt: 2 }}>
					About My Practice
				</Typography>
				<Typography variant="body1" sx={{ color: 'text.secondary', mt: 2, }}>
					Our mission is simple - to make you feel and stay healthy. Whether you’re coming in for obstetrical or gynecological care, we have the skills and resources to take care of your needs. You just need a referral from the walk in doctor or your family doctor.
				</Typography>
			</Box>
		</Container >
	);
}
