import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import lavalDoc from '../assets/laval/laval_doc.jpg';
import { Span } from '@mui/material';
import theme from '../theme';
import AboutMeModal from './AboutModal';
import Social from './Social';

const StyledBox = styled('div')(({ theme }) => ({
	alignSelf: 'center',
	width: '100%',
	height: 400,
	marginTop: theme.spacing(4),
	borderRadius: theme.shape.borderRadius,
	outline: '1px solid',
	boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
	backgroundImage: `url(${lavalDoc})`,
	outlineColor: 'hsla(220, 25%, 80%, 0.5)',
	backgroundSize: 'cover',
	[theme.breakpoints.up('sm')]: {
		marginTop: theme.spacing(8),
		height: 400,
		width: 400,
	},
	...theme.applyStyles('dark', {
		boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
		backgroundImage: `url(${lavalDoc})`,
		outlineColor: 'hsla(210, 100%, 80%, 0.1)',
	}),
}));

export default function Hero() {
	return (
		<Box
			id="hero"
			sx={(theme) => ({
				width: '100%',
				backgroundRepeat: 'no-repeat',
				backgroundImage:
					'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
				...theme.applyStyles('dark', {
					backgroundImage:
						'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
				}),
				px: { xs: 2, sm: 4 },
			})}
		>
			<Container
				sx={{
					display: 'flex',
					flexDirection: { xs: 'column', md: 'row' },
					alignItems: 'center',
					justifyContent: 'space-between',
					pt: { xs: 14, md: 20 },
					pb: { xs: 8, md: 12 },
					flexDirection: 'column',
					width: '100%',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: { xs: 'column', md: 'row' },
						alignItems: 'center',
						width: '100%',
						px: { xs: 2, sm: 4 },
					}}
				>
					{/* Left */}
					<Stack
						spacing={2}
						useFlexGap
						sx={{
							alignItems: { xs: 'center', md: 'flex-start' },
							justifyContent: 'center',
							width: { xs: '100%', md: '70%' }
						}}
					>
						<Typography
							variant="h1"
							sx={{
								display: 'flex',
								flexDirection: { xs: 'column', sm: 'row' },
								alignItems: 'center',
								justifyContent: { xs: 'center', md: 'flex-start' },
								textAlign: 'left',
								flexWrap: 'wrap',
								fontSize: '2rem',
							}}

						>
							<Box
								sx={(theme) => ({
									fontSize: 'inherit',
									color: 'primary.main',
									...theme.applyStyles('dark', {
										color: 'primary.light',
									}),
									display: 'flex',
									alignItems: 'center',
									justifyContent: { xs: 'center', md: 'flex-start' },
									flexWrap: 'wrap',
									textAlign: { xs: 'center', md: 'left' },
								})}
							>
								<Box sx={{
									fontSize: 'inherit',
									color: 'primary.dark',
									...theme.applyStyles('dark', {
										color: 'primary.light',
									}),
									display: 'flex',
									alignItems: { xs: 'center', md: 'flex-start' },
									justifyContent: { xs: 'center', md: 'flex-start' },
									mr: 1,
								}}>
									Dr.
									<Box
										sx={(theme) => ({
											fontSize: 'inherit',
											color: 'primary.main',
											...theme.applyStyles('dark', {
												color: 'primary.light',
											}),
											display: 'flex',
											alignItems: { xs: 'center', md: 'flex-start' },
											justifyContent: { xs: 'center', md: 'flex-start' },
											flexWrap: 'wrap',
											ml: 1,
										})}
									>Sharon</Box>
								</Box>
								Michelle Laval
							</Box>
						</Typography>
						<Typography
							sx={{
								textAlign: { xs: 'center', md: 'left' },
								color: 'text.secondary',
								width: { sm: '100%', md: '80%' },
								fontSize: '1.5rem',
							}}
						>
							Obstetrician Gynaecologist.
						</Typography>
						


						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							spacing={1}
							useFlexGap
							sx={{ pt: { xs: 0, md: 4 }, width: { xs: '100%', sm: 'auto' } }}
						>
							{/* <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
              Email
            </InputLabel> */}
							{/* <TextField
              id="email-hero"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              slotProps={{
                htmlInput: {
                  autoComplete: 'off',
                  'aria-label': 'Enter your email address',
                },
              }}
            /> */}

							<AboutMeModal />
						</Stack>
						<Social />
						{/* <Typography
							sx={{
								textAlign: { xs: 'center', md: 'left' },
								color: 'text.secondary',
								width: { sm: '100%', md: '80%' },
								fontSize: '1.0rem',
							}}
						>
							Doctor at Appletree Medical Group, Ottawa, Ontario, Canada.
						</Typography> */}

						{/* <Typography variant="caption" sx={{ textAlign: 'center' }}>
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography> */}
					</Stack>
					{/* Right */}
					<StyledBox id="image" />

				</Box>
				<Box sx={{
					mt: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
				}}>
					<Typography
						sx={{
							textAlign: 'left',
							color: 'text.secondary',
							width: { sm: '100%', md: '80%' },
							fontSize: '1.25rem',
							fontFamily: 'Borel',
						}}
					>
						“Providing Personalized Care and Guidance for your Health and Wellness.”
					</Typography>
				</Box>
			</Container>
		</Box >
	);
}
