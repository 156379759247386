import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';

const Social = () => {
	return (
		<>
			<Stack
				direction="row"
				spacing={1}
				useFlexGap
				sx={{ justifyContent: 'left', color: 'text.secondary' }}
			>
				<IconButton
					color="inherit"
					href="https://www.facebook.com/sharon.laval.10"
					aria-label="Facebook"
					sx={{ alignSelf: 'center' }}
				>
					<FacebookIcon />
				</IconButton>
				<IconButton
					color="inherit"
					href="https://twitter.com/DoctorLaval"
					aria-label="X"
					sx={{ alignSelf: 'center' }}
				>
					<TwitterIcon />
				</IconButton>
				<IconButton
					color="inherit"
					href="https://ca.linkedin.com/in/sharon-laval-6705b4107"
					aria-label="LinkedIn"
					sx={{ alignSelf: 'center' }}
				>
					<LinkedInIcon />
				</IconButton>
			</Stack>
		</>
	)
}

export default Social