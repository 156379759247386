import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LavalField from '../assets/laval/laval_field.jpg'
import LavalRed from '../assets/laval/laval_red.jpg'

const Separator = () => {
	return (
		<Box sx={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: '100%',
		}}>


			<Box sx={{
				width: '65%',
				height: '3px',
				backgroundColor: 'text.secondary',
				marginTop: 2,
				marginBottom: 2,
			}} />
		</Box>

	)
};

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '85%',
	maxWidth: 1100,
	bgcolor: 'background.paper',
	border: '2px solid #00000033',
	boxShadow: 24,
	backgroundColor: 'background.paper',
	p: 4,
	maxHeight: '80vh',
	overflow: 'auto',
};

export default function AboutMeModal() {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<>
			<Button variant="contained" color="info" onClick={handleOpen}>
				More about me
			</Button>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Box sx={{
							display: 'flex',
							flexDirection: {
								xs: 'column-reverse',
								md: 'row',
							},
							alignItems: 'flex-start',
							// justifyContent: 'space-between',
							gap: 2,
							width: '100%',

						}}>
							{/* Close icon */}
							<Box sx={{
								position: 'fixed',
								right: 0,
								top: 8,
							}}>
								<Button onClick={handleClose} sx={{
									borderRadius: '50%',
									backgroundColor: 'background.paper',
									color: 'text.primary',
									fontSize: '1.5rem',
									'&:hover': {
										backgroundColor: 'background.paper',
									},
								}}>X</Button>

							</Box>
							<Box sx={{
								// maxHeight: '250px',
								// minWidth: '150px',
								// maxWidth: '250px',
								width: '100%',
								overflow: 'hidden',
								// display: 'block',
								justifyContent: 'flex-start',
							}}>
								<img src={LavalRed} style={{
									/* width: {
										md: '100%',
										xs: '100%',
									}, */
									// height: '100%',
									objectFit: {
										xs: 'cover',
										md: 'contain',
										lg: 'contain',
									},
									// display: 'block',
									// position: 'relative',
									borderRadius: '8px',
									width: "100%",
								}}
								/>
							</Box>
							<Box sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								justifyContent: 'flex-start',
								width: '100%',
								mt: {
									xs: 2,
									md: 0,
								}
							}}>
								<Typography id="transition-modal-title" variant="h5" component="h2" sx={{ mt: 1, mb: 1, fontFamily: "Borel, cursive", }}>
									Dedicated Women's Health Specialist
								</Typography>
								<Typography id="transition-modal-title" variant="h6" component="h3" sx={{
									fontSize: '1.05rem',
								}} >
									Providing Expert Obstetrical and Gynecological Care for Over 20 Years, Serving Appletree Patients with Compassion and Expertise Since 2013
								</Typography>
								<Separator />
								<Typography id="transition-modal-description" sx={{ mt: 2 }}>
									I am committed to providing the care you deserve. I have been an obstetrician gynaecologist for 20 years. I provide office obstetrics and gynaecology to meet your needs. I have been an Obstetrician Gynaecologist at Appletree since 2013. I have a wait time of about 4 months for consults. I enjoy assisting the following types of patients: Obstetrics, Infertility, PAP (routine, abnormal, colposcopy), Prolapse, Vulvar lesions (Vestibular papillomatosis, genital warts, Bartholin Duct cysts, Lichen Sclerosis, Lichen Planus, Lichen Simplex chronicus, Gartner's Duct Cyst), Abnormal Bleeding (PCOS, Postmenopausal, Menorrhagia, Amenorrhea), Pelvic Pain (Dyspareunia, Vaginismus, Dysmenorrhea), Abnormal discharge, Urinary symptoms (incontinence, interstitial cystitis, recurrent UTI), Contraception (Counselling, IUD insertion, removal of Implanon), Breast (Nipple discharge, pain), and Premenstrual Syndrome.
								</Typography>
								<Separator />
								<Typography id="transition-modal-credentials" sx={{ mt: 2 }}>
									<b>Memorial University, Newfoundland and Labrador</b><br />
									FRCSC, Obstetrics and Gynecology Residency Program | 1998 - 2005<br />
									Resident in Obstetrics and Gynecology<br />
									<br />
									<b>University of Toronto logo</b><br />
									Doctor of Medicine (M.D.), Medicine | 1992 - 1998<br />
									Activities and societies: Hart House Choir<br />
									Medicine<br />
									<br />
									<b>York University</b><br />
									Master's degree, Genetics | 1990 - 1992<br />
									Activities and societies: Don at Stong College<br />
									Honours Bachelor of Science<br />
									<br />
									<b>York University</b><br />
									Bachelor's degree, Science | 1986 - 1990<br />
									Activities and societies: Teaching assistant<br />
								</Typography>
							</Box>
						</Box>
					</Box>
				</Fade>
			</Modal>
		</>
	);
}
