import * as React from 'react';
import Link from '@mui/material/Link';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import axios from 'axios';
import theme from '../theme';

const Separator = () => {
	return (
		<Box sx={{
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'flex-start',
			width: '100%',
			height: '100%',
		}}>
			<Box sx={{
				width: '65%',
				height: '3px',
				backgroundColor: 'text.secondary',
				marginTop: 2,
				marginBottom: 2,
			}} />
		</Box>
	)
};

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '85%',
	maxWidth: 1100,
	bgcolor: 'background.paper',
	border: '2px solid #00000033',
	boxShadow: 24,
	backgroundColor: 'background.paper',
	p: 4,
	maxHeight: '80vh',
	overflow: 'auto',
};

const API_KEY = 'pat0Cke5iZT4uEu3Q.39e5f24e4683a49908cb8ff422b3acd46f3c663bbecc12c9d0df36df3f93b464'

const fetchLinks = async ({ fetchLink }) => {
	try {
		const response = await axios(
			{
				method: 'get',
				url: fetchLink,
				headers: {
					'Authorization': `Bearer ${API_KEY}`,
					'Content-Type': 'application/json'
				}
			}
		).catch(error => console.log(`fetchLinks | error - `, error));

		console.log(`fetchLinks | response - `, response);

		if (response?.status !== 200) {
			console.log(`fetchLinks | bad response - `, response);
			return [];
		}

		const records = await response?.data?.records;
		if (!records) {
			console.log(`fetchLinks | no records - `, records);
			return [];
		}
		console.log(`fetchLinks | records - `, records);
		return records?.map(record => ({
			name: record.fields.Name,
			url: record.fields.Link,
		})) || [];
	} catch (error) {
		console.log(`fetchLinks | error - `, error);
	}
};

export default function ObsLinksModal({ fetchLink, btnTitle, title }) {
	const [open, setOpen] = React.useState(false);
	const [links, setLinks] = React.useState([]);
	const handleOpen = () => {
		setOpen(true);
		displayLinks();
	};
	const handleClose = () => setOpen(false);

	const displayLinks = async () => {
		const fetchedLinks = await fetchLinks({ fetchLink });
		setLinks(fetchedLinks);
	};

	return (
		<>
			<Link
				color="primary"
				variant="body2"
				onClick={(event) => {
					event.stopPropagation();
					handleOpen();
				}}
				sx={{
					fontWeight: 'bold',
					display: 'inline-flex',
					alignItems: 'center',
					'& > svg': { transition: '0.2s' },
					'&:hover > svg': { transform: 'translateX(2px)' },
				}}
			>
				<span>{btnTitle}</span>
				<ChevronRightRoundedIcon
					fontSize="small"
					sx={{ mt: '1px', ml: '2px' }}
				/>
			</Link>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Box sx={{
							display: 'flex',
							flexDirection: {
								xs: 'column-reverse',
								md: 'row',
							},
							alignItems: 'flex-start',
							gap: 2,
							width: '100%',
						}}>
							{/* Close icon */}
							<Box sx={{
								position: 'fixed',
								right: 0,
								top: 16,
							}}>
								<Button onClick={handleClose} sx={{
									borderRadius: '50%',
									backgroundColor: 'background.paper',
									color: 'text.primary',
									fontSize: '1.5rem',
									'&:hover': {
										backgroundColor: 'background.paper',
									},
								}}>X</Button>
							</Box>
							<Box sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								justifyContent: 'flex-start',
								width: '100%',
								mt: {
									xs: 2,
									md: 0,
								}
							}}>
								<Typography id="transition-modal-title" variant="h5" component="h2" sx={{ mt: 1, mb: 1 }}>
									{title}
								</Typography>
								<Separator />
								<Box sx={{ mt: 2 }}>

									{links?.length > 0 ? (
										links?.map((link, index) => (
											<Box key={index} sx={{
												mb: 2,
												pb: 1,
												borderWidth: 1,
												borderRadius: 16,
												borderBottomStyle: 'solid',
												pt: 0,
												px: {
													xs: 2,
													md: 4,
												},
												// color: 'text.primary',
												borderColor: theme.palette.primary.dark,
												...theme.applyStyles('dark', {
													borderColor: theme.palette.primary.light,
												}),
												display: 'flex', flexDirection: {
													xs: 'column',
													md: 'row',
												}, gap: 1, alignItems: 'center', justifyContent: 'space-between'
											}}>
												<Typography variant="body1" sx={{ fontWeight: 'bold' }}>{link.name}</Typography>
												<Link href={link.url} target="_blank" rel="noopener noreferrer" sx={{ color: 'primary.main' }}>
													Click here
												</Link>
											</Box>
										))
									) : (
										<Typography variant="body2" sx={{ mt: 2 }}>Loading links...</Typography>
									)}

								</Box>
							</Box>
						</Box>
					</Box>
				</Fade>
			</Modal>
		</>
	);
}
